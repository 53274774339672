
/*
 * Page | Home
 */

import { IRouteHandler } from "../engine/routeDispatcher";
 

export class HomePage implements IRouteHandler {

  constructor() {
  }

  setup() {
        
  }

  exec() {

  }

}
